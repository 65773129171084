import React, { useEffect } from "react";
import '../../css/draganddrop.css';
import { useState } from "react";
import { faCirclePlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { format } from "date-fns";
import { SSF, read, utils } from "xlsx";
import { formatInTimeZone } from "../../utils/common";
import moment from "moment-timezone";
import { PlannedMortalitySubmission } from "../../classes/RE/PlannedMortalitySubmission";
import { PlannedMortalitySubmissions } from "../../classes/RE/PlannedMortalitySubmissions";
import {MortalityFactors, isEmptyString, isNaN_Or_NaNstr } from "../../utils/common";

const fileTypes = ["XLSX"];

const dataColumns = {
    tagNumber: "A",
    tagType: "B",
    pen: "C",
    date: "D",
    species: "E",
    class: "F",
    commodity_type: "G",
    date_of_arrival_in_RP: "H",
    PIC: "I",
    euthanased_or_found_deceased: "J",
    was_post_mortem_conducted: "K",
    why_post_mortem_not_conducted: "L",
    diagnosis_level_1: "M",
    diagnosis_level_2: "N",
    diagnosis_level_3: "O",
    other_factors: "P",
    further_info: "Q",
    managementPlan1: "R",
    managementPlan2: "S",
    managementPlan3: "T",
    managementPlan4: "U"
}

const convertData = (targetSheet, dataColumns, rowNumber) => {
    var keys = Object.keys(dataColumns);

    var data = {}
    keys.forEach(key => {
        data[key] = targetSheet[(dataColumns[key] + rowNumber)]?.w.trim()
    });

    return data;
}

const validTagTypes = ["NLIS", "VID", "POO"];

const MortalityDragNDropUploader = ({ onImported, onReset, species, classes, managementPlans, onRaiseError, hasData,
    mortalityConditions,
    commodityTypes,
    tagTypes
}) => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    const [importedData, setImportedData] = useState(null);

    useEffect(() => {
        if (!file) return;
        let reader = new FileReader();
        reader.onload = async (e) => {
            setError(null);
            let errorRaised = false;
            const data = e.target.result;
            const wb = read(data);

            const targetSheetName = "Import Template";
            let targetSheet = wb.Sheets[targetSheetName];
            let livestockData = parseImportDataFromTargetSheet(targetSheet);
            if (livestockData.errorResult?.error) {
                onRaiseError(livestockData.errorResult.errorMsg);
                errorRaised = true;
            }

            if (!errorRaised) {
                let totalData = livestockData.data;
                onImported(totalData);
            }
        }
        reader.readAsArrayBuffer(file);

    }, [file])

    const parseImportDataFromTargetSheet = (targetSheet) => {
        const cellLimits = targetSheet["!ref"].split(":");
        const startRow = 3;
        const lastRow = Number(cellLimits[1].match(/\d+/g));
        let importedData = [];
        let errorResult = undefined;

        // console.log("parseImportDataFromTargetSheet cellLimits", cellLimits);
        // console.log("species", species);
        // console.log("classes", classes);
        // console.log("managementPlans", managementPlans);
        console.log("tagTypes", tagTypes);
        for (let i = startRow; i <= lastRow; i++) {
            let importedDataObject = convertData(targetSheet, dataColumns, i);

            // Ignore empty rows
            if (!allColumnsEmpty(importedDataObject)) {
                errorResult = validateImportedDataObject(importedDataObject, i);
                if (errorResult.error) {
                    break;
                }
                importedData.push(importedDataObject);
                console.log("importedDataObject", importedDataObject);
            }
        }

        if (errorResult?.error) { setError(errorResult.errorMsg); return; }
        else
            setImportedData(importedData);

        var mortalityArray = [];
        importedData.forEach(ar => {
            mortalityArray.push(
                new PlannedMortalitySubmission(
                    moment(ar.date, "M/D/YY").utc().toDate(),
                    ar.pen,
                    getCommodityType(ar).id,
                    ar.euthanased_or_found_deceased === 'Euthanased',
                    ar.was_post_mortem_conducted === 'Yes',
                    ar.why_post_mortem_not_conducted === undefined?"":ar.why_post_mortem_not_conducted,
                    getMortalityCondition(ar).levelThree.id,
                    getTagType(ar).ID,
                    ar.tagNumber,
                    ar.PIC,
                    getMortalityFactor(ar),//mortalityFactorsArray
                    moment(ar.date_of_arrival_in_RP, "M/D/YY").utc().toDate(),
                    getSpecies(ar).id,
                    getSpeciesClass(ar).id,
                    getManagementPlans(ar).map(m=>m.id),
                    (ar.further_info === undefined ? "":ar.further_info)
                )
            )
        });

        return { data: new PlannedMortalitySubmissions(mortalityArray), errorResult: errorResult }
    }

    // Only returns true if all of the columns are undefined.
    // Returns false if any column has data (i.e. is not undefined)
    const allColumnsEmpty = (row) => {
        let allColumnsEmpty = true;
        Object.keys(row).forEach(k => {
            if (!isEmptyString(row[k]) || !isNaN_Or_NaNstr(row[k])) {
                allColumnsEmpty = false;
            }
        }
        )
        return allColumnsEmpty;
    }

    const getTagType = (data) => {
        return tagTypes.find(t => t.TagType.toUpperCase() === data.tagType.toUpperCase())
    }

    const getMortalityCondition = (data) => {
        return mortalityConditions.find(m => {
            let space = /\s+/g;

            // if(m.levelOne.conditionName.replace(space, '').toLowerCase() === data.diagnosis_level_1.replace(space, '').toLowerCase()
            //     && m.levelTwo.conditionName.replace(space, '').toLowerCase() === data.diagnosis_level_2.replace(space, '').toLowerCase()) 
            // console.log(m.levelThree.conditionName.replace(space, '').toLowerCase(),data.diagnosis_level_3.replace(space, '').toLowerCase())

            return m.levelOne.conditionName.replace(space, '').toLowerCase() === data.diagnosis_level_1.replace(space, '').toLowerCase()
                && m.levelTwo.conditionName.replace(space, '').toLowerCase() === data.diagnosis_level_2.replace(space, '').toLowerCase()
                && m.levelThree.conditionName.replace(space, '').toLowerCase() === data.diagnosis_level_3.replace(space, '').toLowerCase()
        }
        );
    }

    const getSpecies = (data) => {
        return species.find(s => s.speciesName.toUpperCase() === data.species.toUpperCase())
    }

    const getSpeciesClass = (data) => {
        return classes.find(s => s.className.toUpperCase() === data.class.toUpperCase())
    }

    const getMortalityFactor = (data) => {
        if(data.other_factors === undefined||data.other_factors === "None") return [];
        return MortalityFactors.filter(m => m.label.toUpperCase() === data.other_factors.toUpperCase())
    }

    const getCommodityType = (data) => {
        return commodityTypes.find(c => c.speciesID === getSpecies(data).id && c.commodityName.toUpperCase() === data.commodity_type.toUpperCase())
    }

    const getManagementPlans = (data) => {
        if (data.managementPlan1 !== undefined) {
            let space = /\s+/g;
            let sID = getSpecies(data).id;
            return managementPlans.filter(m => {
                return m.managementPlanSpeciesID === sID
                    && (m.managementPlanName.replace(space, '').toLowerCase() === data.managementPlan1?.replace(space, '').toLowerCase()
                        || m.managementPlanName.replace(space, '').toLowerCase() === data.managementPlan2?.replace(space, '').toLowerCase()
                        || m.managementPlanName.replace(space, '').toLowerCase() === data.managementPlan3?.replace(space, '').toLowerCase()
                        || m.managementPlanName.replace(space, '').toLowerCase() === data.managementPlan4?.replace(space, '').toLowerCase()
                    )
            }
            );
        }
        return [];
    }

    const buildError = (msg, rowNumber) => {
        return {
            error: true,
            errorMsg: `Import error at row ${rowNumber}. ${msg}`
        }
    }

    const validateImportedDataObject = (data, rowNumber) => {
        var isValidTag = validTagTypes.includes(data.tagType.toUpperCase());

        if (data.tagNumber && data.tagNumber.length > 0 && (!data.tagType || !isValidTag)) {
            return buildError("One or more rows have tag numbers but do not specify a valid tag type.", rowNumber);
        }

        if (data.tagType && isValidTag && (!data.tagNumber || data.tagNumber.length === 0)) {
            return buildError("One or more rows have a tag type specified but do not provide a valid tag number.", rowNumber);
        }

        let validSpecies = species.find(sp => sp.speciesName.toLowerCase() === data.species.toLowerCase());
        if (data.species.length === 0 || !validSpecies) {
            return buildError("One or more rows has an invalid species name.", rowNumber);
        }

        if (data.class.length === 0 || !classes.find(c => c.className.toLowerCase() === data.class.toLowerCase())) {
            return buildError("One or more rows has an invalid livestock class name.", rowNumber);
        }

        var m = getMortalityCondition(data);
        if (m === undefined) {
            return buildError("Diagnosis Levels is incorrect.", rowNumber);
        }

        var mplans = getManagementPlans(data);
        // console.log("mplans", mplans);
        if (data.managementPlan1 && data.managementPlan1.length > 0 && !managementPlans.find(mp => Number(mp.managementPlanSpeciesID) === Number(validSpecies.id) && mp.managementPlanName.trim().toLowerCase() === data.managementPlan1.trim().toLowerCase())) {
            return buildError("Invalid management plan 1.", rowNumber);
        }

        if (data.managementPlan2 && data.managementPlan2.length > 0 && !managementPlans.find(mp => Number(mp.managementPlanSpeciesID) === Number(validSpecies.id) && mp.managementPlanName.trim().toLowerCase() === data.managementPlan2.trim().toLowerCase())) {
            return buildError("Invalid management plan 2.", rowNumber);
        }

        if (data.managementPlan3 && data.managementPlan3.length > 0 && !managementPlans.find(mp => Number(mp.managementPlanSpeciesID) === Number(validSpecies.id) && mp.managementPlanName.trim().toLowerCase() === data.managementPlan3.trim().toLowerCase())) {
            return buildError("Invalid management plan 3.", rowNumber);
        }

        if (data.managementPlan4 && data.managementPlan4.length > 0 && !managementPlans.find(mp => Number(mp.managementPlanSpeciesID) === Number(validSpecies.id) && mp.managementPlanName.trim().toLowerCase() === data.managementPlan4.trim().toLowerCase())) {
            return buildError("Invalid management plan 4.", rowNumber);
        }

        return { error: false, errorMsg: "" };
    }

    const handleChange = (file) => {
        setFile(file);
    }

    const clearFile = () => {
        onReset();
        setFile(null);
        setError(null);
        setImportedData(null);
    }

    return (
        <Container >
            <Container className="d-flex justify-content-center align-items-center h-100">
                <FileUploader
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                    classes="drop_zone"
                >
                    <Container className="px-3 text-center py-5" style={{ minHeight: "120px", minWidth: "100%" }} >
                        {!file ? (
                            <><FontAwesomeIcon icon={faCirclePlus} size="lg" /><span>&nbsp;Drag and drop your completed template here, or &nbsp;</span><Button className="btn-primary" >SELECT FILE</Button></>
                        ) :
                            (
                                <>
                                    <Row className="text-center py-3">
                                        <div><b>Filename:</b> {file.name}</div>
                                        <div><b>Last Modified:</b> {format(new Date(file.lastModified), "dd/MM/yyyy HH:mm")}</div>
                                        <div><b>Size:</b> {Math.round(file.size / 1000)}kb</div>

                                    </Row>
                                </>
                            )
                        }
                    </Container>
                </FileUploader>
            </Container>
            {
                file || hasData === true ? (
                    <Row>
                        <Col xs={12} className="text-center mt-2"><Button variant="secondary" onClick={() => clearFile()}>Reset Imported Data</Button></Col>
                    </Row>
                ) : null
            }
            {
                error != null &&
                <Row>
                    <Col xs={12} className="text-center mt-2">
                        <p className="error-red">{error}</p>
                    </Col>
                </Row>
            }

            {error == null && importedData != null && importedData.length > 0 &&
                <>
                    <Row>
                        <Col xs={12} className="mt-2">
                            <p className=""> Mortality Records: <b>{importedData.length}</b></p>
                        </Col>
                    </Row>
                    <Row className="mt-3 mb-2">
                        <Col xs={2} className="table-column-heading">Mortality Date</Col>
                        <Col xs={3} className="table-column-heading">Animal</Col>
                        <Col xs={2} className="table-column-heading">Tag</Col>
                        <Col xs={5} className="table-column-heading">Diagnosis/Presumptive Diagnosis</Col>
                    </Row>
                    {
                        importedData.map((m, i) => {
                            let bgColor = i % 2 ? "#FFFFFF" : "#E3E3E3";
                            return (
                                <Row key={`imported_mortality_${m.tagNumber}_${i}`} className="py-2" style={{ backgroundColor: bgColor, borderBottom: "1px solid #44cb6f" }}>
                                    <Col xs={2}>
                                        <Stack direction="vertical">
                                            {/* {moment(m.date, "M/D/YY").utc().format("DD/MM/YYYY")} */}
                                            {<p className="text-13">{formatInTimeZone(moment(m.date, "M/D/YY").utc().toDate(), "dd/MM/yyyy", Intl.DateTimeFormat().resolvedOptions().timeZone)}</p>}
                                        </Stack>
                                    </Col>
                                    <Col xs={3}>
                                        <Stack direction="vertical">
                                            <p className="text-13">{m.species} {m.class}</p>
                                            <p className="text-13">{m.commodity_type}</p>
                                        </Stack>
                                    </Col>
                                    <Col xs={2}>
                                        <p className="text-13"> {m.tagType}-{m.tagNumber}  </p>
                                    </Col>
                                    <Col xs={5}>
                                        <Stack direction="vertical">
                                            <div className="text-13">{m.diagnosis_level_1}</div>
                                            <div className="ms-2 text-13">{m.diagnosis_level_2}</div>
                                            <div className="ms-4 text-13">{m.diagnosis_level_3}</div>
                                        </Stack>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                </>
            }
        </Container>
    )
}

export default MortalityDragNDropUploader;